<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <!-- <div>
          <span>Tìm theo ngày thanh toán</span>
          <Calendar id="expiration_dateasdad" dateFormat="yy-mm-dd" :showClear="true" v-model="expiration_date" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" :showIcon="true" class="p-col-width" placeholder="Ngày thanh toán"/>
        </div> -->
        <div class="f-grid-ind">
          <div class="f-grid-col-ind">
            <div class="idn-sel">
              <span class="ind-text">Từ ngày: </span>
              <Calendar dateFormat="dd-mm-yy" :showClear="true" v-model="filter_date.from" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" :showIcon="true" class="p-col-width" placeholder="Ngày thanh toán" @clear-click="onChangeData()" @date-select="onChangeData()"
              />
            </div>
          </div>
          <div class="f-grid-col-ind">
            <div class="idn-sel">
              <span class="ind-text">Đến ngày: </span>
              <Calendar dateFormat="dd-mm-yy" :showClear="true" v-model="filter_date.to" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" :showIcon="true" class="p-col-width" placeholder="Ngày thanh toán" @clear-click="onChangeData()" @date-select="onChangeData()"
              />
            </div>
          </div>
          <!-- <div class="f-grid-col-ind">
            <div class="ind-text">a</div>
            <div class="idn-sel">
              <Button label="Tìm kiếm" @click="deleteData()" icon="pi pi-search" class="" />
            </div>
          </div> -->
          
        </div>
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :filterDefault="filters"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          :filterDate="filter_date"
          v-on:action-click="onActionClicked"
          @onDataChange = 'DataOnChange'
          @filter_total = 'TinhTong'
        ></BaseDatatable>
        <div class="cus-price" v-html="htmlTotalCommission"></div>
        <Dialog
          style=""
          header="Xóa"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          <div style="width : 450px;">
            Bạn có chắc muốn xóa <strong>{{this.deleteName}}?</strong>
          </div>  
          <template
            #footer
          >
            <Button
              label="Hủy"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning"
            />
            <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
export default {
  components: {
    BaseDatatable,
  },
  data() {
    return {
      filters: {},
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      page_transaction:null,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      dataDelete :{},
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      expiration_date : null,
      htmlTotalCommission : '',
      yearRange : '2024:2124',
      filter_date: {
        from : null,
        to : null
      }
    }
  },
  mounted () {
    this.initParams();
    var year = new Date().getFullYear()
    var yearRange = '2024:'+(year+200);
    this.yearRange = yearRange+'';
    this.loadTotal()
    this.modelName = this.$route.meta.model;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.filter_date = {
      from : startOfMonth,
      to : today
    }
  },
  async created() {
    
    // if (!this.$commonFuction.isEmpty(this.$route.query)){
    //   if (!this.$commonFuction.isEmpty(this.$route.query.cid)){
    //     this.filters['courses_id'] = this.$route.query.cid
    //     // setTimeout(() => {
    //     //   const query = Object.assign({}, this.$route.query);
    //     //   delete query.cid;
    //     //   this.$router.replace({ query });
    //     // }, 100);
    //   }
    //   if (!this.$commonFuction.isEmpty(this.$route.query.ch)){
    //     this.filters['chapter_id'] = this.$route.query.ch
    //     // setTimeout(() => {
    //     //   const query = Object.assign({}, this.$route.query);
    //     //   delete query.ch;
    //     //   this.$router.replace({ query });
    //     // }, 100);
    //   }
    // }
    console.log("this.modelName",this.modelName)
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_STATISTICAL);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    initParams() {
      this.$store.commit('setLoading', true);
      this.modelName = this.$route.meta.model;
      this.dataModel = DataServices.getModel(this.modelName);
      this.title = this.dataModel.label;
      this.callbackOptions();
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    DataOnChange(data,f){
      if(f.type == 'Checkbox'){
        let dataUpdate = {}
        if(f.key == 'active'){
          dataUpdate['active'] = data[f.key]
        }
        let variables = {
          'data_update': dataUpdate,
          'where_key': {
            'id': {'_eq': data.id}
          }
        };
        this.$apollo.mutate({
          mutation: DataServices.updateData(this.modelName),
          variables: variables
        }).then(() => {})
      }
    },
    async TinhTong(data){
      this.loadTotal();
      //gọi load data
      this.showTotal(data)
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      // console.log("field",field)
      var result = []
      if (field.type == 'Selection') {
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      // console.log("result",result)
      return result;
    },
    getRowActions() {
      return null
      // var actions = {}
      // var that = this;
      // if (this.role.add) {
      //   actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      // }
      // // actions.header = { name: 'add', icon: 'plus'};
      // actions.rows = function (rowData) {
      //   var buttons = {}
      //   //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
      //   if (that.role.edit) {
      //     buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
      //   }
      //   if (that.role.delete) {
      //     buttons.delete = {name: 'delete', icon: 'trash', key: 'id'};
      //   }
      //   if (that.dataModel.actions) {
      //     var modelaction = that.dataModel.actions(rowData)
      //     Object.assign(buttons, modelaction);
      //   }
      //   return buttons
      // }
      // return actions
    },
    async callbackOptions() {
      var fields = this.dataModel.getDisplayFields();
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          // console.log("SDFGHJK",element)
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          var where_ = {}
          var {data} = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
            variables: {where_key : where_}
          })
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
              }
            }
          }
          console.log("optionsData",optionsData)
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }

    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.page_transaction = pageTransaction.toString();
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.dataDelete = data.data
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async deleteData() {
      this.showDeleteDialog = false
      var query = DataServices.updateData('lesson')
      var variables = {
        'data_update': {
          'deleted': true
        },
        where_key: {
          id: {'_eq': this.deleteId}
        }
      }
      await this.$apollo.mutate({
        mutation: query,
        variables: variables
      }).then(async (res) => {
        // console.log("res.data.",res.data)
        if (res.data.update_banner.affected_rows > 0) {
          this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Xóa dữ liệu thành công', life: 3000 })
          this.componentKey++
        } else this.$toast.add({ severity: 'error', summary: 'Thất bại', detail: 'Xóa dữ liệu thất bại', life: 3000 })
      })
    },
    loadTotal(){
      this.htmlTotalPrice = `Tổng hoa hồng: <i class="fas fa-spinner fa-spin"></i>`
    },
    showTotal(data){
      // console.log("datata",data)
      var text_c = `0`
      if(data > 0){
        text_c = this.formatCurrencyV2(data)
      }
      this.htmlTotalCommission = `Tổng hoa hồng: `+text_c+` VNĐ`
    },
    formatCurrencyV2(value) {
      return value.toLocaleString("vi-VN", {
        style: "decimal",
      });
    },
    onChangeData(){
      this.filters = {}
      this.componentKey++;
    },
    goAddpage(){
      this.$router.push({ path: '/admin/list-history/add' })
    }
  },
  // apollo: {
  //   listData: {
  //     query() {
  //       this.modelName = this.$route.meta.model;
  //       return DataServices.getList(this.modelName);
  //     },
  //     variables () {
  //       // Use vue reactive properties here
  //       return this.whereKeyList;
  //     },
  //     update(data) {
  //       var listData = data[this.dataModel.tableName]
  //       this.title = this.dataModel.label + ' '
  //       this.dataModel.processData(listData)
  //       this.$store.commit('setLoading', false);
  //       this.componentKey += 1;
  //       return data[this.dataModel.tableName]
  //     },
  //     fetchPolicy: 'network-only',
  //     error() {
  //       this.$store.commit('setLoading', false);
  //     },
  //     skip() {
  //       return !this.modelName
  //     }
  //   }
  // }

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
.txt-div-mid{
  text-align : center !important;
}
.cus-price{
  font-size: 20px;
  font-weight: 500;
  padding-top: 5px;
  font-style: italic;
}
.f-grid-ind {
  display: flex;
  /* margin-left: -1rem; */
  flex-flow: row wrap;
  margin-bottom: 8px;
}
.f-grid-col-ind {
  padding: 0rem 0rem 0rem 1rem;
}
.ind-text { 
  font-weight: 600;
}
.idn-sel {
  margin-top: 8px;
}
.p-inputwrapper-focus .p-dropdown-panel {
    top: 0px !important;
    left: 0px !important;
}
</style>